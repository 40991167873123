import {Response} from '../../../../../../_metronic/helpers'
export type Medium = {
  id_medium?: number
  in_ativo?: number
  nome_medium?: string
  sexo_medium?: string
  foto?: string
  dt_nascimento?: string
  nome_mae?: string
  nome_pai?: string
  email?:string

  templo?: Templo
  dt_ingresso?: string
  dt_emplac?: string
  princesaDTO?: PrincesaDTO
  mentores?: string
  falangeMissionaria?: FalangeMissionariaDTO
  in_janda?: number
  id_adjunto_devas?: number
  cod_adjunto_apoio?: number
  
  dt_iniciacao?: string
  mediunidadeDTO?: MediunidadeDTO

  dt_elevacao?: string
  falangeMestrado?: FalangeMestradoDTO
  classeDTO?: ClasseDTO
  subclasseDTO?: SubclasseDTO

  dt_centuria?: string
  nome_emite?: string
  povo?: PovoDTO
  adjunto_raiz?: AdjuntoRaizDTO
  turno_trabalho?: TurnoTrabalhoDTO

  ordemDTO?: OrdemDTO


  adjunto_transito?: AdjuntoTransitoDTO

  ministro?: MinistroDTO
  dt_cav_guia?: string
  cavaleiro_guia?: CavaleiroGuiaDTO
  turnos_cavaleiro?: TurnosCavaleiroDTO

  estrela?: EstrelaDTO
  lanca?: LancaDTO


  dt_ultima_classificacao?: string
  classificacaoDTO?: ClassificacaoDTO

  representante_cavaleiro?: RepresentanteCavaleiroDTO
  dt_rep_cavaleiro?: string

  dt_setimo?: string

  in_comandante_janata?: number
  id_comandante_janata?: string
  in_padrinho?:number
  id_padrinho?: number
  in_madrinha?: number
  id_madrinha?: number
  in_escrava?: number
  id_escrava?: number
  in_recepcionista?: number
  in_filho_devas?: number

  trino?: TrinoDTO
  id_mestre_que_rege?: number //1-YPOARÃ/2-TANARÃ/3-OLATANO

  observacoes?: string

  online?: boolean
  initials?: {
    label: string
    state: string
  }
}

export type MediumAudit = {
  id_medium_audit?: number,
  id_medium_requester?: number,
  audited?: Medium,
  requester?: Medium,
  id_medium_approver?: number,
  dt_request?: string,
  dt_approve?: string,
  justify_unapprove?: string,
  id_medium?: number,
  mediunidadeDTO?: MediunidadeDTO,
  templo?: Templo,
  princesaDTO?: PrincesaDTO,
  falangeMestrado?: FalangeMestradoDTO,
  falangeMissionaria?: FalangeMissionariaDTO,
  in_janda?: number,
  adjunto_raiz?: AdjuntoRaizDTO,
  classificacaoDTO?: ClassificacaoDTO,
  ordemDTO?: OrdemDTO,
  classeDTO?: ClasseDTO,
  subclasseDTO?: SubclasseDTO,
  povo?: PovoDTO,
  ministro?: MinistroDTO,
  cavaleiro_guia?: CavaleiroGuiaDTO,
  turnos_cavaleiro?: TurnosCavaleiroDTO,
  turno_trabalho?: TurnoTrabalhoDTO,
  estrela?: EstrelaDTO,
  lanca?: LancaDTO,
  representante_cavaleiro?: RepresentanteCavaleiroDTO,
  dt_rep_cavaleiro?: string,
  trino?: TrinoDTO,
  mentores?: string,
  dt_ingresso?: string,
  dt_emplac?: string,
  dt_iniciacao?: string,
  dt_elevacao?: string,
  dt_centuria?: string,
  dt_setimo?: string,
  dt_cav_guia?: string,
  dt_ultima_classificacao?: string,
  in_comandante_janata?: number,
  id_comandante_janata?: string,
  in_filho_devas?: number,
  in_recepcionista?: number,
}

export function is_trino(medium: Medium) {
  return (medium.trino?.CodTrino || 0) !== 0;
}

export function is_trinos_sardyos_e_solitario(medium: Medium) {
  const is_mestre = medium?.sexo_medium === 'M';
  return is_mestre && [6,7,8,9,10].includes(medium.trino?.CodTrino || 0 ) // trinos sardios e trinos solitario
}

export function is_trino_herdeiro(medium: Medium) {
  const is_mestre = medium?.sexo_medium === 'M';
  return is_mestre && [2].includes(medium.trino?.CodTrino || 0 ) // trino herdeiro
}

export function is_trino_maiores(medium: Medium) {
  const is_mestre = medium?.sexo_medium === 'M';
  return is_mestre && [1,2].includes(medium.trino?.CodTrino || 0 ) // trinos presidentes e trinos herdeiros
}


export function is_trino_regente(medium: Medium) {
  const is_mestre = medium?.sexo_medium === 'M';
  return is_mestre && [5].includes(medium.trino?.CodTrino || 0 ) // trino regente
}

export function is_trino_admin_devas(medium: Medium) {
  const is_mestre = medium?.sexo_medium === 'M';
  return is_mestre && [13].includes(medium.trino?.CodTrino || 0 ) // trino administracao devas
}

export function is_adjunto_raiz(medium: Medium) {
  const is_mestre = medium?.sexo_medium === 'M';
  return is_mestre && medium.adjunto_raiz?.id_medium_representante === medium.id_medium;
}

export function is_doutrinador(medium: Medium) {
  return medium.mediunidadeDTO?.id_mediunidade === 2;
}

export type FileResp = {
  image_url: string
  message: string
}

export type Templo = {
  id_templo?: number
  in_templo_ativo?: number
  tx_cidade?: string
  tx_estado?: string
  tx_cep?: string
  tx_pais?: string
  tx_sigla?: string
  tx_ministro_presidente?: string
  tx_origem_presidente?: string
  tx_nome_presidente?: string
  tx_endereco?: string
  tx_email_presidente?: string
  tx_cpf_presidente?: string
  tx_telefone_presidente?: string
  id_medium_presidente?: number
}

export type Course = {
  id_curso?: number
  nome_curso?: string
  ds_curso?: string
  dt_curso?: string
  tipo_curso?: number
  in_ativo?: number
  tipoCurso?: CourseType
  templo?: Templo
}

export type CourseType = {
  id_tipo: number
  ds_tipo: string
}

export type EnrollCourse = {
  id_inscricao?: number
  id_medium?: number
  id_curso?: number
  in_ativo?: number
  dt_hora_inscricao?: string
  medium?: Medium
  curso?: Course
}

export type EscalaDevas = {
  id_escala?: number
  templo?: Templo
  devas_escalado?: Medium
  in_ativo?: number
  init_date?: string
  end_date?: string
}

export type MediunidadeDTO = {
  id_mediunidade?: number
  nome_mediunidade?: string
}

export type PrincesaDTO = {
  id_princesa?: number
  nome_princesa?: string
}

export type FalangeMissionariaDTO = {
  id_falange_missionaria?: number
  nome_falange_missionaria?: string
  prefixo?: string
  primeiro_falange?: string
  adjunto_apoio?: string
  adjunto_apoio_2?: string
  adjunto_apoio_3?: string
  sexo?: string
  nome_falange_carteirinha?: string
}


export type FalangeMestradoDTO = {
  id_falange_mestrado?: number
  nome_falange_mestrado?: string
}

export type ClasseDTO =  {
  CodMestre?: number
  NomeMestreDesc?: string
  ClasMestre?: string
  tx_classe_carteirinha?: string
  ClasMestrechamada?: string
}

export type SubclasseDTO = {
  CodSMestre?: number
  SubClasM?: string
}

export type AdjuntoRaizDTO = {
  id_adjunto_raiz?: number
  nome_adjunto_raiz?: string
  id_medium_representante?: number
  mestre_representante?: string
}

export type OrdemDTO = {
  id_ordem?: number
  ordem?: string
  sexo?: string
}

export type AdjuntoTransitoDTO = {
  CodAdjunto?: number
  id_adjunto_origem?: number
  MinAdjTran?: string
  Nome?: string
}

export type PovoDTO = {
  id_povo?: number
  nome_povo?: string
}

export type ClassificacaoDTO = {
  id_classificacao?: number
  nome_classificacao?: string
  mediunidade_classificacao?: string
  tx_classificacao_carteirinha?: string
  id_template_emissao?: number
}

export type RepresentanteCavaleiroDTO = {
  id_representante_cavaleiro?: number
  ds_representante_cavaleiro?: string
}

export type CavaleiroGuiaDTO = {
  id_cavaleiro_guia?: number
  nome_cavaleiro_guia?: string
  sexo?: string
  in_restrito?: number
}

export type TurnosCavaleiroDTO = {
  id_turno_cavaleiro?: number
  nome_turno_cavaleiro?: string
  sexo_turno_cavaleiro?: string
}

export type TurnoTrabalhoDTO = {
  id_turno_trabalho?: number
  nome_turno_trabalho?: string
  sexo_turno_trabalho?: string
}

export type EstrelaDTO = {
  id_estrela?: number
  nome_estrela?: string
  mediunidade_estrela?: string
}

export type LancaDTO = {
  id_lanca?: number
  nome_lanca?: string
}

export type MinistroDTO = {
  id_ministro?: number
  nome_ministro?: string
  in_restrito?: number
}

export type TrinoDTO = {
  CodTrino?: number
  DescTrino?: string
  Trino?: string
  id_template_emissao?: number
}

export type TemplateEmissao = {
  id_template?: number
  nome_template?: string
  prefixo_emissao: string
  emissao: string
}


export const initialMedium: Medium = {
  foto: 'NFoto.jpg',
  nome_medium: '',
  nome_emite: '',
  dt_nascimento: '',
  dt_ingresso: '',
  nome_pai: '',
  sexo_medium: 'N',
  nome_mae: '',
  templo: {id_templo: 1},
}

export const initialEnroll: EnrollCourse = {
  id_curso: 0,
  id_medium: 0,
  dt_hora_inscricao: '',
  in_ativo: 1,
}

export const initialTemplo: Templo = {
  id_templo: 0,
  tx_cidade:'',
  tx_estado:'',
  tx_pais:'',
  tx_sigla:'',
  tx_ministro_presidente:'',
  tx_origem_presidente:'',
  tx_nome_presidente:'',
  tx_endereco:'',
  tx_email_presidente:'',
  tx_cpf_presidente:'',
  tx_telefone_presidente:'',
  id_medium_presidente: 0
}

export type MediumsQueryResponse = Response<Array<Medium>>
export type MediumsAuditQueryResponse = Response<Array<MediumAudit>>
export type EscalaDevasQueryResponse= Response<Array<EscalaDevas>>
export type CoursesQueryResponse = Response<Array<Course>>
export type TemplesQueryResponse = Response<Array<Templo>>
export type FalangeMissionariaQueryResponse = Response<Array<FalangeMissionariaDTO>>
export type FalangeMestradoQueryResponse = Response<Array<FalangeMestradoDTO>>
export type ClasseQueryResponse = Response<Array<ClasseDTO>>
export type SubClasseQueryResponse = Response<Array<SubclasseDTO>>
export type PovoQueryResponse = Response<Array<PovoDTO>>
export type AdjuntoRaizQueryResponse = Response<Array<AdjuntoRaizDTO>>
export type TurnoTrabalhoQueryResponse = Response<Array<TurnoTrabalhoDTO>>
export type MinistroQueryResponse = Response<Array<MinistroDTO>>
export type CavGuiaQueryResponse = Response<Array<CavaleiroGuiaDTO>>
export type TurnoCavGuiaQueryResponse = Response<Array<TurnosCavaleiroDTO>>
export type ClassificacoQueryResponse = Response<Array<ClassificacaoDTO>>
export type MediumQueryResponse = Response<Array<Medium>>
export type PadrinhoQueryResponse = Response<Array<Medium>>
export type MadrinhaQueryResponse = Response<Array<Medium>>
export type NinfaLuaQueryResponse = Response<Array<Medium>>
export type NinfaSolQueryResponse = Response<Array<Medium>>